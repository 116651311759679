.subTitle {
    display: flex;
    justify-content: center;
    font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
}

.columnWithoutPadding {
    padding-left: 0px !important;
}