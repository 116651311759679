.searchSection {
    display: flex;
    justify-content: center;
    margin: 1%;
}
.buttons {
    display: flex;
    justify-content: center;
    margin: 1%;
}
.jobSeciton{
    margin-left: 1%;
}
.subTitle {
    display: flex;
    justify-content: center;
    font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
}