
.searchSection {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2%;
    padding-right: 2%;
    margin-right: 1%; 
}

.searchControle {
    display: flex;
}

.subTitle {
    display: flex;
    justify-content: center;
    font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
}