.retrycard {
    text-align: center;
}
.tableBody {
    display: flex;
    justify-content: center;
    width: auto;
}
.tableHeading{
    display: flex;
    justify-content: center;
    font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
}