.details-section {
    display: flex;
    justify-content: center;
    width: auto;
    padding-left: 2%;
    padding-right: 1%;
}

.details-section-without-padding {
    display: flex;
    justify-content: center;
    width: auto;
}

.no-pointers {
    pointer-events: none;
}

.details-field {
    padding-right: 2%;
    padding-left: 1%;
    align-items: center;
    justify-content: center;
}

.padding {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 8%;
    padding-right: 8%;
    align-items: center;
    justify-content: center;
}

.col-4 {
    width: 35% !important;;
}

.col-1 {
    width: 5% !important;;
}

.col-2 {
    width: 20% !important;;
}

.col-3 {
    width: 25% !important;;
}
