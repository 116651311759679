.table-label {
    color: #af5329;
}

.a-hyperlink {
    color: black !important;
}
a:hover {
    color: #0d6efd !important;
}

.td-compact-table-cell-dashboard {
    padding: 8px 16px 8px 8px !important;
    min-width: 125px !important;
    max-width: 125px !important;
    vertical-align: middle;
    height: 40px !important;
}

.dashboard-display {
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.dashboard-section {
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30%;
}


