.search-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1%;
    padding-right: 1%;
}

.radio-group {
    padding-top: 1px !important;
    margin-bottom: 1px;
    background-color: #f7f7f7 !important;;
}

.min-height-bulk-search {
    padding-top: 5px;
    min-height: 690px !important;
}

.table-column {
    justify-content: center;
    align-items: center;
}


.inline-button-div {
    display: flex;
    align-items: center;
    justify-content: left;
}

.inline-button-span {
    padding-left: 5px;
    padding-right: 5px;
}

.modal-section {
    display: flex;
    align-items: center;
    justify-content: left;
    width: auto;
    padding-left: 5%;
    padding-right: 5%;
}
.anchor_tag {
    font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #757575
}
.anchor_tag:hover {
    color: #757575 !important;
  }

.div-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 5%;
    padding-right: 5%;
}

.result-section-padding {
    padding-top: 10px;
}

.button-section-padding {
    padding-top: 20px;
    padding-right: 20px;
}

.search-section-padding {
    padding-top: 10px;
    padding-left: 50%;
}

.no-pointers-main {
    pointer-events: none;
}

.pointers-main {
    pointer-events: all;
}

.modal-field-span {
    padding-right: 10px;
    padding-left: 10px;
}

.search-field-span {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
    height: 75px;
}
.tooltip {
    visibility: hidden;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 40%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.1s;
}
.search-field-span:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #eaeaea;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
