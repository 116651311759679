.icon-size {
    color: #af5329;
    width: 60px;
    height: 60px;
}

.header {
    position: fixed;
    width: 100%;
    z-index: 9997;
    background-color: #c9c7c4;
    color: black;
    text-align: center;
    padding-top: 5px;
}

.footer {
    position: fixed;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    margin-top: -40px;
    clear: both;
    background-color: #191919;
    color: white;
    text-align: center;
}
.bg-color-tabs {
    background-color: #f0f0f0;
}

.bg-color {
    background-color: #f7f7f7;
}

.bg-height {
    height: 82vh;
}
.back-button {
    padding-right: 2%;
}