.customCard{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    padding: 5%;
    text-align: center;
    background-color: #f1f1f1;
    margin: 10%;
    width: 90%;
    height: auto;
}
.cardBody {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}